import { render, staticRenderFns } from "./SuList.vue?vue&type=template&id=cbd568b6&scoped=true&"
import script from "./SuList.vue?vue&type=script&lang=js&"
export * from "./SuList.vue?vue&type=script&lang=js&"
import style0 from "./SuList.vue?vue&type=style&index=0&id=cbd568b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbd568b6",
  null
  
)

export default component.exports