<!--
 * @FilePath: \zsw_new_edition\src\components\SlideShow.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-18 14:22:20
-->
<!--banner组件-->
<template>
  <div class="bannerBox">
    <el-carousel :autoplay="true" indicator-position="none" arrow="never" :height="bannerHeight+'px'" ref="carousel">
      <el-carousel-item v-for="(item,index) in bannerList" :key="index" name="index">
        <img class="masking" style="width: 100%;height: auto" :src="item.src" alt="" ref="bannerHeight" @load="imgLoad">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "SlideShow",
  props:['bannerList'],
  data() {
    return {
      bannerHeight: 0,

    }
  },
  created() {

  },
  methods: {
    imgLoad() {
      this.bannerHeight = this.$refs.bannerHeight[0].height
      this.$emit('bannerH', this.bannerHeight)
    },
    setActiveItem(cate_id){
			let index
			this.bannerList.forEach((item, i) => {
				if(cate_id == item.cate_id) {
					index = i
				}
			})
      this.$refs.carousel.setActiveItem(index)
    }
  },
  mounted() {
    // this.imgLoad();
    window.addEventListener('resize', () => {
      this.bannerHeight = this.$refs.bannerHeight[0].height
      this.imgLoad()
    }, false)
  }
}
</script>

<style scoped>
.bannerBox {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 0;
}
.bannerBox::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
	z-index:2;
}
</style>